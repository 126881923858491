import React, { useEffect, useState } from "react";
import "./Home.css";

import Home1 from "../../Components/HomeComponent/Home1";
import Home2 from "../../Components/HomeComponent/Home2";
import Home3 from "../../Components/HomeComponent/Home3";
import Home4 from "../../Components/HomeComponent/Home4";
import Home5 from "../../Components/HomeComponent/Home5";
import Home6 from "../../Components/HomeComponent/Home6";
import Home7 from "../../Components/HomeComponent/Home7";
import Home8 from "../../Components/HomeComponent/Home8";
import Navbar from "../../Components/Navbar/Navbar";
const Home = () => {

  const [width, setWidth] = useState(window.innerWidth <= 700);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth <= 700);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  return (
    <>
      {/* <Navbar/> */}
    
      <Home1 width={width} />
      <Home2 width={width} />
      <Home3 width={width} />
      <Home4 width={width} />
      <Home5 width={width} />
      <Home6   width={width} />
      <Home7  width={width}/>
      <Home8  width={width}/>





    </>
  );
};

export default Home;
