import React from "react";
import "./Contact.css";
import { useNavigate } from "react-router-dom";

const Contact1 = ({ width }) => {
  const navigation = useNavigate();

  return (
    <div>
      <div className="about-c1">
        <div className="conact-c3">
          <div className="about-c4">
            <h1 className="about-h1">Contact Me</h1>
            <div className="about-c6">
              <h1 onClick={() => navigation("/")} className="home-navi">
                Home
              </h1>
              <span>/</span>
              <h1 className="about-h2">Contact </h1>
            </div>
          </div>
        </div>
        <div className="contact-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d54872.10243182113!2d76.72951406217962!3d30.732273943392315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fed0be66ec96b%3A0xa5ff67f9527319fe!2sChandigarh!5e0!3m2!1sen!2sin!4v1703520941901!5m2!1sen!2sin"
            // width="100%"
            // height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contact1;
