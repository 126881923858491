import React, { useEffect, useState } from "react";
import "./HomeComponent.css";
import ReactWhatsapp from "react-whatsapp";
const Home1 = ({ width }) => {
  const message = `Name:
  Email id:
  Date of birth:
  Current location:
  Your Contact no?:
  What's your Age?:
  What's your Current diet?:
  What is fitness goal?:
  Any medication you're still on?:
  Do you have any injury or any kind of health issues?:
  How often do you workout?:
  How often do you eat outside food?:
  What is your intensity level of workouts?:
  Do You workout in gym or home?:
  What is your expectation from me as your personal online trainer?`;

  return (
    <div className="home-d1">
      <div className="home-d-2">
        <div
          data-aos-duration="1000"
          data-aos={width ? "zoom-in" : "fade-left"}
          data-aos-offset="300"
          className="home-d-4"
        >
          <h1 className="home-c-h1">
            Time To Bring
            <br />
            Your A-Game
          </h1>
        </div>
        <div
          className="home-d-3"
          data-aos-duration="2000"
          data-aos={width ? "" : "zoom-in"}
        >
          <h1>RUH Tyagi</h1>
          <h1>Online Transformation Program</h1>
        </div>

        <ReactWhatsapp
          number={"+91 75007 52031"}
          message={message}
          data-aos-duration="1000"
          data-aos="fade-up"
          data-aos-anchor-placement="bottom-bottom"
          className="custom-btn btn-4"
        >
          JOIN NOW
        </ReactWhatsapp>
      </div>
    </div>
  );
};

export default Home1;
