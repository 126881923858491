import React, { useState } from "react";
import "./HomeComponent.css";
import toast from "react-hot-toast";
const Home6 = ({width}) => {
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [gender, setGender] = useState("male");
  const [age, setAge] = useState("");
  const [bmi, setBMI] = useState(null);
  const [bmr, setBMR] = useState(null);

  const calculateBMI = () => {
    if (!weight || !height) {
      toast.error("fill required fields");
    } else {
      if (weight && height && age) {
        const heightInMeters = height / 100; // Convert height to meters
        const bmiValue = (weight / (heightInMeters * heightInMeters)).toFixed(
          2
        );

        setBMI(bmiValue);
      } else {
        setBMI(null);
      }
    }
  };

  const calculateCorrectedBMI = () => {
    if (bmi && gender === "female" && age) {
      let correctedBMI = bmi - 0.7;
      if (age > 64) {
        correctedBMI -= 0.1;
      }
      return correctedBMI.toFixed(2);
    }
    return bmi;
  };

  const calculateBMR = () => {
    if (weight && height && age && gender) {
      let bmrValue = null;
      if (gender === "male") {
        bmrValue = 88.362 + 13.397 * weight + 4.799 * height - 5.677 * age;
      } else {
        bmrValue = 447.593 + 9.247 * weight + 3.098 * height - 4.33 * age;
      }
      setBMR(bmrValue.toFixed(2));
    } else {
      setBMR(null);
    }
  };

  const handleWeightChange = (e) => {
    setWeight(e.target.value);
  };

  const handleHeightChange = (e) => {
    setHeight(e.target.value);
  };

  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const handleAgeChange = (e) => {
    setAge(e.target.value);
  };

  const handleCalculate = () => {
    calculateBMI();
    calculateBMR();
  };

  const data = [
    { id: 1, name: "Below 18.5", age: 25, email: "Underweight" },
    { id: 2, name: "18.5 - 24.9", age: 30, email: "Healthy" },
    { id: 3, name: "25.0 - 29.9", age: 28, email: "Overweight" },
    { id: 3, name: "30.0 - and Above", age: 28, email: "Obese" },
  ];
  return (
    <div className="hc-6-1">
      <div  className="hc-6-2">
        <h1
        data-aos-duration="2000"
        data-aos="zoom-in"
        className="hc-6-h1">
          Calculate your Bmi and Bmr
          <br />
          know your body
        </h1>
      </div>
      <div className="bmr-bmi-form-d1">
        <div className="bmr-d1">
          <div data-aos-duration="1000"    data-aos={ width? "zoom-in"   :    "fade-right"}  className="form-lh">
            <div className="bmr-head-h-p">
              <h1>
                Bmi <br />
                calculator
              </h1>

              <p>
                BMI( Body Mass Index) is a calculated result indicating the body
                fat, based on your height and weight. You can fill the values in
                the columns given below and calculate BMI real time.
              </p>
            </div>
            <div className="bmr-result1">
              <div className="bmr-result2">
                {bmi && (
                  <div className="bmr-result3">
                    <h3>Your BMI is: {calculateCorrectedBMI()}</h3>
                  </div>
                )}
              </div>
              <div className="bmr-result2">
                {bmr && (
                  <div className="bmr-result3">
                    <h3>Your BMR is: {bmr} calories/day</h3>
                  </div>
                )}
              </div>
            </div>

            <div>
              <div className="form-bmr">
                <div className="div-input-bmr">
                  <div className="input-bmr-div">
                    <input
                      placeholder="  Height (cm)"
                      type="number"
                      value={height}
                      onChange={handleHeightChange}
                    />
                  </div>
                  <div className="input-bmr-div">
                    <input
                      placeholder="Weight (kg)"
                      type="number"
                      value={weight}
                      onChange={handleWeightChange}
                    />
                  </div>
                </div>
                <div className="div-input-bmr">
                  <div className="input-bmr-div">
                    <input
                      placeholder=" Age:"
                      type="number"
                      value={age}
                      onChange={handleAgeChange}
                    />
                  </div>
                  <div className="input-bmr-div">
                    <select value={gender} onChange={handleGenderChange}>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                </div>

                <div className="btn-cal-c1">
                  <button className="btn-cal-1" onClick={handleCalculate}>
                    Calculate
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div data-aos-duration="1000"    data-aos={ width? "zoom-in"   :    "fade-left"}  className="form-rh">
            <div className="table-container">
              <table className="styled-table">
                <thead>
                  <tr>
                    <th>BMI</th>
                    <th>WEIGHT STATUS</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((row) => (
                    <tr key={row.id}>
                      <td>{row.name}</td>
                      <td>{row.age}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <h1 className="table-rh-h1">
              <span>BMR</span> Metabolic Rate / <span>BMI</span> Body Mass Index
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home6;
