import React, { useEffect, useState } from "react";
import Contact1 from "../../Components/Contact/Contact1";
import Home8 from "../../Components/HomeComponent/Home8";

const Contact = () => {


    const [width, setWidth] = useState(window.innerWidth <= 700);

    useEffect(() => {
      const handleResize = () => {
        setWidth(window.innerWidth <= 700);
      };
  
      window.addEventListener("resize", handleResize);
  
      // Clean up the event listener
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  return (
    <>
      <Contact1 width={width} />
      <Home8  width={width}/>

    </>
  );
};

export default Contact;
