import React from "react";
import ReactWhatsapp from "react-whatsapp";

const Home3 = ({width}) => {
  const message = `Name:
  Email id:
  Date of birth:
  Current location:
  Your Contact no?:
  What's your Age?:
  What's your Current diet?:
  What is fitness goal?:
  Any medication you're still on?:
  Do you have any injury or any kind of health issues?:
  How often do you workout?:
  How often do you eat outside food?:
  What is your intensity level of workouts?:
  Do You workout in gym or home?:
  What is your expectation from me as your personal online trainer?`;



  return (
    <div className="hc3-d1">
      <div   data-aos-duration="2000"
         data-aos="zoom-in" className="hc3-d2">
        <div className="lh-hc3">
          <h1 className="lh-hc3-h1">
          8-12-16 <br /> Weeks TRANSFORMATION <br /> plans
          </h1>
          <h1 className="lh-hc3-h2">PROGRAM SPECIFICATION</h1>
          <div>
            <h1 className="lh-hc3-h3">
              - Customised Workout Plan 
              <br />
              -  Personalization  Meal Plan
              <br />- Motivation & Mindset Support
              <br />- WhatsApp and Instagram Support 24*7
            </h1>
          </div>


          <ReactWhatsapp
          number={"+91 75007 52031"}
          message={message}
    
        className="btn-hc3-1"
        >
      Start Now!!
        </ReactWhatsapp>

      
        </div>
        <div className="rh-hc3">
          <img src={require("../../assets/ruh3.jpg")} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Home3;
