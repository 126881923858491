import React from "react";
import "./Policy.css";
import { useNavigate } from "react-router-dom";
const Refund = () => {
  const navigation = useNavigate();

  return (
    <div>
      <div className="about-c1">
        <div className="return-c3">
          <div className="about-c4">
            <h1 className="about-h1">REFUND & RETURN POLICY</h1>
            <div className="about-c6">
              <h1 onClick={() => navigation("/")} className="home-navi">
               Home
              </h1>
              <span>/</span>
              <h1 className="about-h2">Policy </h1>
            </div>
          </div>
        </div>

        <div className="term-d1">
          <div className="term-d2">
            <div>
              <h1 className="term-head">REFUND & RETURN POLICY</h1>
              <p className="term-para">
                THANK YOU FOR SHOPPING AT RUH TYAGI FITNESS. THIS IS THE
                RETURNS AND REFUND POLICY. PLEASE READ THIS POLICY CAREFULLY
                BEFORE MAKING ANY PURCHASES WITH RUH TYAGI.
                <br />
                <br />
                SALES FOR ALL PRODUCTS AND SERVICES ARE FINAL. OUR DIGITAL
                PRODUCTS/SERVICES CANNOT BE RETURNED AND ARE THEREFORE
                NON-REFUNDABLE. OUR COACHING AND PERSONAL TRAINING SERVICES ARE
                WITHOUT GUARANTEE AND ALSO NON-REFUNDABLE. ANY PHYSICAL PRODUCT
                PURCHASED CAN BE REFUNDED IF ANY DEFECT IS FOUND.
                <br />
                <br />
                OUR GOAL AT RUH TYAGI FITNESS IS TO PROVIDE YOU WITH THE
                BEST FITNESS TRAINING AND COACHING AVAILABLE. WE UNDERSTAND THAT
                THERE MAY BE TIMES WHEN YOU HAVE CONCERNS ABOUT YOUR PURCHASE
                AND MAY REQUIRE A REFUND. WE ARE HAPPY TO HEAR ANY CONCERNS YOU
                MAY HAVE, ALONG WITH ANY REASONS YOU FEEL THAT YOU SHOULD BE
                GRANTED A REFUND
                <br />
                <br />
                PLEASE CONTACT US AT INFO@RUHTYAGI.COM FOR ANY OF THESE
                CONCERNS AND SOMEONE WILL RESPOND TO YOU WITHIN ONE TO TWO
                BUSINESS DAYS.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Refund;
