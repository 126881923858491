import React from "react";
import "./About.css";
import { useNavigate } from "react-router-dom";

const About1 = ({width}) => {
  const navigation = useNavigate();

  return (
    <div className="about-c1">
      <div className="about-c3">
        <div className="about-c4">
          <h1 className="about-h1">About Me</h1>
          <div className="about-c6">
            <h1 onClick={() => navigation("/")} className="home-navi">
              Home
            </h1>
            <span>/</span>
            <h1 className="about-h2">About Me</h1>
          </div>
        </div>
      </div>

      <div className="about-c2">
        <div className="about-c7">
          <div className="about-c8">
            <div 
           data-aos={width ? "zoom-in" : "fade-right"}

            data-aos-duration="1000"
            
            className="lh-about">
              <h1>
                Ruh Tyagi
                <br />
                BREAKING THE STEREOTYPES
              </h1>
              <p>
                My name is Ruh Tyagi and I am a men's physique competitor from
                India. I have been training and dieting for the past 6 years and
                started competing in 2021. <br />  <br />  I have won all my shows at the
                amateur level since then, moving from local show to pro
                qualifier show to national level and ultimately fighting to get
                my pro card. <br />  <br /> I debuted at Amateur Olympia India 2023 where I got
                placed 3rd in my very first show as a men's physique athlete.
                I've won so many NPC regionals and national shows. <br /> <br /> As a
                professional banker myself, I know how hard it is to balance
                healthy eating and fitness with a demanding profession. Through
                my hectic desk job profile, my goal is to help improve one's
                physique through optimal training and nutrition. <br /> <br /> It is about
                getting better: physically, emotionally, and mentally. Whatever
                I have experienced in these years of grinds, I wanna share my
                knowledge with you all. Let's get healthier and stronger
                together.
              </p>
            </div>

            <div
         data-aos={width ? "zoom-in" : "fade-left"}

            data-aos-duration="1000"
            className="rh-about">
              <img src={require("../../assets/life.jpeg")} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About1;
