import React, { useEffect, useState } from "react";
import "./Footer.css";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
import { IoArrowRedo } from "react-icons/io5";
import ReactWhatsapp from "react-whatsapp";
import { useNavigate } from "react-router-dom";
const Footer = () => {
  const navigation = useNavigate();

  const [width, setWidth] = useState(window.innerWidth <= 700);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth <= 700);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const message = `Name:
  Email id:
  Date of birth:
  Current location:
  Your Contact no?:
  What's your Age?:
  What's your Current diet?:
  What is fitness goal?:
  Any medication you're still on?:
  Do you have any injury or any kind of health issues?:
  How often do you workout?:
  How often do you eat outside food?:
  What is your intensity level of workouts?:
  Do You workout in gym or home?:
  What is your expectation from me as your personal online trainer?`;

  return (
    <div className="footer-d1">
      <div className="footer-d2">
        <div className="social-links">
          <a
            data-aos={width ? "zoom-in" : "fade-right"}
            data-aos-duration="1000"
            href="https://twitter.com/RuhTyagi1"
            target="blank"
          >
            <div className="social-link1">
              <FaXTwitter size={25} />
              <h1 className="social-link-name">@RuhTyagi1</h1>
            </div>
          </a>
          <a
            data-aos={width ? "zoom-in" : "fade-right"}
            data-aos-duration="1000"
            href="https://www.instagram.com/ruhtyagi/"
            target="blank"
          >
            <div className="social-link1">
              <FaInstagram size={25} />
              <h1 className="social-link-name">@ruhtyagi</h1>
            </div>
          </a>
          <a
            data-aos={width ? "zoom-in" : "fade-left"}
            data-aos-duration="1000"
            href="https://www.instagram.com/ruhtyagi/"
            target="blank"
          >
            <div className="social-link1">
              <FaFacebookF size={25} />
              <h1 className="social-link-name">@ruhtyagi</h1>
            </div>
          </a>
          <a
            data-aos={width ? "zoom-in" : "fade-left"}
            data-aos-duration="1000"
            href="https://www.instagram.com/ruhtyagi/"
            target="blank"
          >
            <div className="social-link1">
              <FaLinkedinIn size={25} />
              <h1 className="social-link-name">@ruhtyagi</h1>
            </div>
          </a>
        </div>
      </div>
      <div className="footer-main">
        <div className="footer-main-2">
          <div className="ql-head-d1">
            <h1 className="footer-heading-ql">Contact Me</h1>
            <ul className="links-ul">
              <span>For our customized online transformation program.</span>

              <ReactWhatsapp
                number={"+91 75007 52031"}
                message={message}
                className="btn-footer-click"
              >
                Click Here <IoArrowRedo />
              </ReactWhatsapp>
            </ul>
          </div>
          <div className="ql-head-d1">
            <h1 className="footer-heading-ql">Quick Links</h1>
            <ul className="links-ul">
              <li onClick={() => navigation("/")}>Home</li>
              <li onClick={() => navigation("/About")}>About</li>
              <li onClick={() => navigation("/Contact")}>Contact Me</li>
            </ul>
          </div>
          <div className="ql-head-d1">
            <h1 className="footer-heading-ql">Policy</h1>
            <ul className="links-ul">
              <li onClick={() => navigation("/Policy")}>Terms & Conditions</li>
              <li onClick={() => navigation("/returns-refund")}>
                Refund Policy
              </li>
            </ul>
          </div>
        </div>
        <div className="copy-right-div">
          <p className="copy-right-footer">
            Copyright © Ruh Tyagi. All Rights Reserved.
            <br />
          </p>

          <a target="blank" href="https://ashishkhanna.in/">
            <p className="copy-right-footer">Design by Ashish Khanna</p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
