import React, { useState } from "react";
import "./HomeComponent.css";
import { FaCheck } from "react-icons/fa6";
import ReactWhatsapp from "react-whatsapp";
const Home5 = ({ width }) => {
  const plans = [
    {
      id: 1,
      name: "Lifestyle fitness program",
      price: "₹5000/Month",

      duration: "Monthly",
      image: require("../../assets/life.jpeg"),

      features1: "Access to all the features",
      features2: "Personalised workout program WEEKLY CHECK-INS",
      features3: "Personalised Nutrition plan",

      features4: "Private Whats App Group ",
      features5: "Whats App / Insta Support ",

      message: " Hi I'm Interested in LIFESTYLE FITNESS PROGRAM",
    },
    {
      id: 2,
      name: "Male fitness competition prep",
      price: "₹5000/Month",

      duration: "Monthly",
      image: require("../../assets/card3.jpg"),
     

  
        features1: "PERSONALIZED NUTRITION PLANS",
        features2: " CUSTOM WORKOUT PROGRAMS",
        features3: " REGIMENTED CARDIO PLANS",
  
        features4: "PED GUIDANCE AND HEALTH MONITORING",
        features5: "WEEKLY CHECK-INS",

      message: "Hi I'm Interested in MALE FITNESS COMPETITION PREP",
    },
    {
      id: 3,
      name: "Female fitness competition prep",
      price: "₹5000/Month",

      duration: "Monthly",
      image: require("../../assets/zeba3.jpeg"),


      features1: "PERSONALIZED NUTRITION PLANS",
      features2: " CUSTOM WORKOUT PROGRAMS",
      features3: " REGIMENTED CARDIO PLANS",

      features4: "PED GUIDANCE AND HEALTH MONITORING",
      features5: "WEEKLY CHECK-INS",
      message: "Hi I'm Interested in FEMALE FITNESS COMPETITION PREP",

    },
  ];

  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");

  return (
    <div className="hc5-1">
      {/* <div className="content">
        <h1 className="title">React Whatsapp</h1>
        <section className="library">
          <input
            id="number"
            placeholder="Number"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
          />
          <input
            id="message"
            placeholder="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <ReactWhatsapp
            number={"+91 75007 52031"}
            message={"hello how are you"}
          >
            Open Whatsapp
          </ReactWhatsapp>
        </section>
      </div> */}

      <div className="hc5-2">
        <div className="hc5-3">
          <h1 className="hc5-h1">TRANSFORMATION PROGRAMS</h1>
          <h1 className="hc5-h2">Choose Your Plan</h1>

          <div
            className="pricing-card-hc5   "
            data-aos-duration="2000"
            data-aos="zoom-in"
          >
            {plans.map((item) => (
              <>
                <div className="card-price-1">
                  <img className="card-price-img" src={item.image} alt="" />

                  {/* <div>
                    <h2>{item.price}</h2>
                    <h2>{item.duration}</h2>
                  </div> */}

                  <div className="card-price-d3">
                    <div className="card-price-d6">
                      <h1 className="plan-card-head">{item.name}</h1>
                    </div>
                    <div className="cards-points">
                      <h1>{item.features1}</h1>
                      <h1>{item.features2}</h1>
                      <h1>{item.features3}</h1>
                      <h1>{item.features4}</h1>
                      <h1>{item.features5}</h1>
                      <h1>{item.features6}</h1>
                    </div>

                    <div className="btn-d1-jn">
                      <ReactWhatsapp
                        number={"+91 75007 52031"}
                        message={item.message}
                        className="jn-btn-price"
                      >
                        know More
                      </ReactWhatsapp>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home5;
