import React from "react";
import "./Navbar.css";
import { NavLink, useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigation = useNavigate();

  return (
    <div className="div-nav-1">
      <div className="div-nav2">
        <div className="nav-logo-div">
          <h1 onClick={() => navigation("/")} className="logo-nav-h1">
            Ruh Tyagi
          </h1>
        </div>
        <div className="nav-links">
          <ul className="nav-ul1">
            <li>
              <NavLink to={"/"}>Home</NavLink>
            </li>
            <li>
              <NavLink to={"/About"}>About</NavLink>
            </li>
            <li >

            <NavLink to={"/Contact"}>Contact</NavLink>


            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
