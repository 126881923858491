import React, { useState } from "react";
import "./HomeComponent.css";
import ReactWhatsapp from "react-whatsapp";
const Home8 = ({width}) => {
  const [message, setMessage] = useState("");

  return (
    <div className="hc-8-1">
      <div  className="hc-8-4">
        <h1  data-aos="zoom-in" data-aos-duration="1000">
          For Emergency, mail at : <br /> Tyagiruh@gmail.com
        </h1>
        <p data-aos="zoom-in"   data-aos-duration="1000">Contact No : +91 75007 52031 </p>
      </div>

      <div
      data-aos="zoom-in"

      data-aos-duration="1000"
      
      className="hc-8-2">
        <div className="hc-8-3">
          <div className="hc-8-5">
            <div className="hc-8-lh">
              <h1>FEEL FREE TO ASK ANYTHING</h1>
              <div className="hc-8-rh">
                <textarea
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                  type="text"
                  placeholder="Message"
                />

                <ReactWhatsapp
                  number={"+91 75007 52031"}
                  message={message}
                  className="jn-btn-price"
                >
                  Send
                </ReactWhatsapp>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home8;
