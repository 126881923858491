import React, { useEffect, useState } from "react";
import "./About.css";
import About1 from "../../Components/About/About1";
const Aboutus = () => {
  const [width, setWidth] = useState(window.innerWidth <= 700);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth <= 700);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <About1 width={width} />
    </>
  );
};

export default Aboutus;
