import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Navbar from "./Components/Navbar/Navbar";
import { Toaster } from "react-hot-toast";
import Footer from "./Components/Footer/Footer";
import Aboutus from "./Pages/About/Aboutus";
import Contact from "./Pages/Contact/Contact";
import ScrollToTop from "./Components/ScrollToTop";
import ScrollTo from "./Components/ScrollTo";
import Policy from "./Pages/Policy/Policy";
import Refund from "./Pages/Policy/Refund";

const App = () => {
  return (
    <Router>
      <ScrollTo />
      <Toaster />
      <ScrollToTop />

      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/About" element={<Aboutus />} />
        <Route exact path="/Contact" element={<Contact />} />
        <Route exact path="/Policy" element={<Policy />} />
        <Route exact path="/returns-refund" element={<Refund/>} />

      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
